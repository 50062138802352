import { GENERATE_PROGRAM_REPORT } from 'volto-yc-assessment/constants/ActionTypes';

const initialState = {
  loaded: false,
  loading: false,
  error: null,
};

export default function generate_report(state = initialState, action = {}) {
  switch (action.type) {
    case `${GENERATE_PROGRAM_REPORT}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GENERATE_PROGRAM_REPORT}_SUCCESS`:
      return {
        ...state,
        ...action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${GENERATE_PROGRAM_REPORT}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
