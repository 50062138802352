import { MY_ID } from 'volto-yc-myapi/constants/ActionTypes';

const initialState = {
  sam_account_name: '',
  mail: '',
};

export default function myIdReducer(state = initialState, action) {
  switch (action.type) {
    case `${MY_ID}_SUCCESS`:
      return {
        ...state,
        sam_account_name: action.result.sam_account_name,
        mail: action.result.mail,
      };
    default:
      return state;
  }
}
