import React from 'react';
import { Container, Form, Message, Segment, Grid } from 'semantic-ui-react';
import DocsPortalImage from './documents-portal-unauthenticated.svg';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
const Unauthorized = () => {
  let location = useLocation();
  return (
    <Container>
      <Form>
        <title>Unauthorized</title>
        <h1>Protected Resource</h1>
        <br />
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment.Group raised>
                <Message info>
                  <Message.Header>
                    Unauthorized
                  </Message.Header>
                  <span>
                    Your current user is not authorized to access this resource.
                    log in using your{' '}
                    <a href="https://www.york.cuny.edu/myid">
                      York College Network Account
                    </a>{' '}
                     (e.g. jdoe7).<br /><br/>
                    <FormattedMessage
                      id="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
                      defaultMessage="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
                      values={{
                        site_admin: (
                          <Link to="/contact-form">
                            <FormattedMessage
                              id="Site Administration"
                              defaultMessage="Site Administration"
                            />
                          </Link>
                        ),
                      }}
                    />
                  </span>
                </Message>{' '}
              </Segment.Group>
              <br />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Link
                      className="ui button primary fluid"
                      to={`/login?return_url=${location.pathname}`}
                    >
                      Login
                    </Link>
                    <p>&nbsp;<br/>&nbsp;</p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <img
                src={DocsPortalImage}
                alt={DocsPortalImage}
                style={{ width: '50%' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Container>
  );
};
// export default Unauthorized;

export default withServerErrorCode(401)(Unauthorized);
