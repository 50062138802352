import { UPDATE_EDITABLE_DATA } from 'volto-yc-assessment/constants/ActionTypes';

const initialState = {
  loaded: false,
  loading: false,
  error: null,
};

export default function update_editable_data(state = initialState, action= {}) {
  switch (action.type) {
    case `${UPDATE_EDITABLE_DATA}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${UPDATE_EDITABLE_DATA}_SUCCESS`:
      return {
        ...state,
        ...action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${UPDATE_EDITABLE_DATA}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
