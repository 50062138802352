import React from 'react';
import loadable from '@loadable/component';
import { Loader } from 'semantic-ui-react';

// Load reducers as usual
import search from '@plone/volto/reducers/search/search';
import content from '@plone/volto/reducers/content/content';
import generate_report from 'volto-yc-assessment/reducers/generate_report/generate_report';
import generate_program_report from 'volto-yc-assessment/reducers/generate_program_report/generate_program_report';
import sharing from '@plone/volto/reducers/sharing/sharing';
import schema from '@plone/volto/reducers/schema/schema';
import update_editable_data from 'volto-yc-assessment/reducers/update_editable_data/update_editable_data';
import save_yams_data from 'volto-yc-assessment/reducers/save_yams_data/save_yams_data';
import unit_goals from 'volto-yc-assessment/reducers/unit_goals/unit_goals';

// Dynamically load views and widgets using @loadable/component
const UnitView = loadable(() => import('volto-yc-assessment/views/UnitView'), {
  fallback: <Loader active inline='centered' />,
});
const MidYearProgramView = loadable(() => import('volto-yc-assessment/views/MidYearProgramView'), {
  fallback: <Loader active inline='centered' />,
});
const MidYearView = loadable(() => import('volto-yc-assessment/views/MidYearView'), {
  fallback: <Loader active inline='centered' />,
});
const UnitPlan = loadable(() => import('volto-yc-assessment/views/UnitPlan'), {
  fallback: <Loader active inline='centered' />,
});
const UnitReportView = loadable(() => import('volto-yc-assessment/views/UnitReportView'), {
  fallback: <Loader active inline='centered' />,
});
const ReportedDataAnalysis = loadable(() => import('volto-yc-assessment/views/ReportedDataAnalysis'), {
  fallback: <Loader active inline='centered' />,
});
const UnitPlanProgramView = loadable(() => import('volto-yc-assessment/views/UnitPlanProgramView'), {
  fallback: <Loader active inline='centered' />,
});
const UnitProgramView = loadable(() => import('volto-yc-assessment/views/UnitProgramView'), {
  fallback: <Loader active inline='centered' />,
});
const UnitProgramReportView = loadable(() => import('volto-yc-assessment/views/UnitProgramReportView'), {
  fallback: <Loader active inline='centered' />,
});
const ReviewComponent = loadable(() => import('volto-yc-assessment/views/ReviewComponent'), {
  fallback: <Loader active inline='centered' />,
});
const PeriodicReviewComponent = loadable(() => import('volto-yc-assessment/views/PeriodicReviewComponent'), {
  fallback: <Loader active inline='centered' />,
});
const UnitGoal = loadable(() => import('volto-yc-assessment/widgets/unit_goal/UnitGoal'), {
  fallback: <Loader active inline='centered' />,
});

const applyConfig = (config) => {
  config.addonReducers.unit_goals = unit_goals;
  config.addonReducers.save_yams_data = save_yams_data;
  config.addonReducers.update_editable_data = update_editable_data;
  config.addonReducers.search = search;
  config.addonReducers.schema = schema;
  config.addonReducers.sharing = sharing;
  config.addonReducers.content = content;
  config.addonReducers.generate_report = generate_report;
  config.addonReducers.generate_program_report = generate_program_report;

  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      assessment_unit: UnitView,
      assessment_unit_program: UnitProgramView,
      unit_midyear_program: MidYearProgramView,
      unit_midyear: MidYearView,
      unit_plan: UnitPlan,
      unit_plan_program: UnitPlanProgramView,
      unit_report: UnitReportView,
      unit_report_program: UnitProgramReportView,
      unit_review_selfstudy: PeriodicReviewComponent,
      unit_review_report: PeriodicReviewComponent,
      unit_review_actionplan: PeriodicReviewComponent,
      program_review_selfstudy: PeriodicReviewComponent,
      program_review_report: PeriodicReviewComponent,
      program_review_actionplan: PeriodicReviewComponent,
      unit_plan_program_review: ReviewComponent,
      unit_report_program_review: ReviewComponent,
      unit_plan_review: ReviewComponent,
      unit_report_review: ReviewComponent,
    },
    layoutViews: {
      ...config.views.layoutViews,
      'mid-year-program-view': MidYearProgramView,
      'mid-year-view': MidYearView,
      'unit-plan': UnitPlan,
      'unit-plan-program-view': UnitPlanProgramView,
      'unit-report-view': UnitReportView,
      'unit-program-report-view': UnitProgramReportView,
      'unit-view': UnitView,
      'unit-program-view': UnitProgramView,
      'reported-data-analysis': ReportedDataAnalysis,
    },
  };

  config.settings.nonContentRoutes.push('/generate-report');
  config.settings.nonContentRoutes.push('/generate-program-report');

  // Widget configuration
  config.widgets.widget.pa_unit_goal = UnitGoal;
  config.widgets.widget.pap_unit_goal = UnitGoal;
  config.widgets.widget.ra_unit_goal = UnitGoal;
  config.widgets.widget.ran_unit_goal = UnitGoal;
  config.widgets.widget.rci_unit_goal = UnitGoal;
  config.widgets.widget.rpa_unit_goal = UnitGoal;
  config.widgets.widget.rpan_unit_goal = UnitGoal;
  config.widgets.widget.rpci_unit_goal = UnitGoal;

  return config;
};

export default applyConfig;
