export const GET_COLLEGE_VOTES = 'GET_COLLEGE_VOTES';
export const COLLEGE_VOTE = 'COLLEGE_VOTE';
export const CLEAR_COLLEGE_VOTES = 'CLEAR_COLLEGE_VOTES';
export const GET_DEPARTMENT_VOTES = 'GET_DEPARTMENT_VOTES';
export const DEPARTMENT_VOTE = 'DEPARTMENT_VOTE';
export const CLEAR_DEPARTMENT_VOTES = 'CLEAR_DEPARTMENT_VOTES';
export const RAW = 'RAW';
export const GET_CITATIONS = 'GET_CITATIONS';
export const CERTIFY_VOTES = 'CERTIFY_VOTES';
export const GET_FACULTY_DATA = 'GET_FACULTY_DATA';
export const UPDATE_EDITABLE_DATA = 'UPDATE_EDITABLE_DATA';
