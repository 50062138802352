import { MY_CASE } from 'volto-yc-myapi/constants/ActionTypes';

const initialState = {
  queue_name: '',
  case_id: '',
  user_empid: '',
  created: '',
  service: '',
  last_comment: '',
  status_name: '',
  description: '',
  department: '',
  submitter_name: '',
  first_name: '',
  last_name: '',
  work_phone: '',
  email_address: '',
};

export default function case_reducer(state = initialState, action) {
  switch (action.type) {
    case `${MY_CASE}_SUCCESS`:
      return {
        ...state,
        queue_name: action.result.queue_name,
        case_id: action.result.case_id,
        user_empid: action.result.user_empid,
        created: action.result.created,
        service: action.result.service,
        last_comment: action.result.last_comment,
        status_name: action.result.status_name,
        description: action.result.description,
        department: action.result.department,
        submitter_name: action.result.submitter_name,
        first_name: action.result.first_name,
        last_name: action.result.last_name,
        work_phone: action.result.work_phone,
        email_address: action.result.email_address,
      };
    default:
      return state;
  }
}
