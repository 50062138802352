/**
 * Voting reducer.
 * @module reducers/votes/votes
 */

import {
  GET_COLLEGE_VOTES,
  CLEAR_COLLEGE_VOTES,
  COLLEGE_VOTE,
} from 'volto-facultycv/constants/ActionTypes';

const initialState = {
  loaded: false,
  loading: false,
  error: null,
};

/**
 * Voting reducer.
 * @function votes
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function college_votes(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_COLLEGE_VOTES}_PENDING`:
    case `${COLLEGE_VOTE}_PENDING`:
    case `${CLEAR_COLLEGE_VOTES}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_COLLEGE_VOTES}_SUCCESS`:
      return {
        ...state,
        ...action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${COLLEGE_VOTE}_SUCCESS`:
    case `${CLEAR_COLLEGE_VOTES}_SUCCESS`:
      return {
        ...state,
        ...action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${GET_COLLEGE_VOTES}_FAIL`:
    case `${COLLEGE_VOTE}_FAIL`:
    case `${CLEAR_COLLEGE_VOTES}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
