/**
 * Voting reducer.
 * @module reducers/votes/votes
 */

import { GET_REVIEW_STATE } from '../../constants/ActionTypes';

const initialState = {
  loaded: false,
  loading: false,
  error: null,
};

/**
 * Voting reducer.
 * @function votes
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function review_state(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_REVIEW_STATE}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_REVIEW_STATE}_SUCCESS`:
      return {
        ...state,
        ...action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${GET_REVIEW_STATE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
