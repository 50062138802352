import { SMART_CLASSROOM, ALL_SMART_CLASSROOMS } from 'volto-yc-myapi/constants/ActionTypes';

const initialState = {
  academic_schedule: '',
  building: '',
  room: '',
  room_status: '',
  status_notes: '',
  floor: '',
  corridor: '',
  room_type: '',
  room_type_description: '',
  capacity: '',
  room_description: '',
  special_equipment: '',
  student_computers: '',
  net_strength: '',
  os_type: '',
  control_panel: '',
  audio: '',
  flat_screen_tv: '',
  lectern_screen: '',
  lectern_connectivity: '',
  projection_screen: '',
  wireless_projection: '',
  screen_information: '',
  board_information1: '',
  board_information2: '',
  font_size_arial: '',
  font_size_tnr: '',
  instructions: '',
  picture1: '',
  picture2: '',
  picture3: '',
  picture4: '',
  picture5: '',
  picture6: '',
  picture7: '',
  room_pic: '',
  topic_id: '',
};

export default function smart_classroom_reducer(state = initialState, action) {
  switch (action.type) {
    case `${SMART_CLASSROOM}_SUCCESS`:
    case `${ALL_SMART_CLASSROOMS}_SUCCESS`:
      return {
        ...state,
        academic_schedule: action.result.academic_schedule,
        building: action.result.building,
        room: action.result.room,
        room_status: action.result.room_status,
        status_notes: action.result.status_notes,
        floor: action.result.floor,
        corridor: action.result.corridor,
        room_type: action.result.room_type,
        room_type_description: action.result.room_type_description,
        capacity: action.result.capacity,
        room_description: action.result.room_description,
        special_equipment: action.result.special_equipment,
        student_computers: action.result.student_computers,
        net_strength: action.result.net_strength,
        os_type: action.result.os_type,
        control_panel: action.result.control_panel,
        audio: action.result.audio,
        flat_screen_tv: action.result.flat_screen_tv,
        lectern_screen: action.result.lectern_screen,
        lectern_connectivity: action.result.lectern_connectivity,
        projection_screen: action.result.projection_screen,
        wireless_projection: action.result.wireless_projection,
        screen_information: action.result.screen_information,
        board_information1: action.result.board_information1,
        board_information2: action.result.board_information2,
        font_size_arial: action.result.font_size_arial,
        font_size_tnr: action.result.font_size_tnr,
        instructions: action.result.instructions,
        picture1: action.result.picture1,
        picture2: action.result.picture2,
        picture3: action.result.picture3,
        picture4: action.result.picture4,
        picture5: action.result.picture5,
        picture6: action.result.picture6,
        picture7: action.result.picture7,
        room_pic: action.result.room_pic,
        topic_id: action.result.topic_id,
      };
    default:
      return state;
  }
}
