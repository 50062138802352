/**
 * File view component.
 * @module components/theme/View/FileView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
// import LoadablePDFViewer from '@eeacms/volto-pdf-block/components/manage/PDFViewer/PDFViewer';
import loadable from '@loadable/component';
import '@eeacms/volto-pdf-block/components/manage/PDFViewer/pdf-styling.css';
import NavigationToolbar from '@eeacms/volto-pdf-block/components/manage/PDFViewer/PDFNavigation';

const CSS_UNITS = 96 / 72;


/**
 * File view component class.
 * @function FileView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const LoadablePDFViewer = loadable(() => import('@eeacms/volto-pdf-block/components/manage/PDFViewer/PDFViewer'), {
  fallback: () => <div>Loading PDF file...</div>, ssr: false,
});
const FileView = ({ content }) => {
  const Container = config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const [size, setSize] = React.useState();
  return (<Container className='view-wrapper'>
    <h1 className='documentFirstHeading'>
      {content.title}
      {content.subtitle && ` - ${content.subtitle}`}
    </h1>
    {content.description && (<p className='documentDescription'>{content.description}</p>)}
    {content.file['content-type'].startsWith('application/pdf') && (
      <div className='pdf-viewer-block'>
        <LoadablePDFViewer
          document={{
            url: flattenToAppURL(content.file.download),
          }}
          css='pdf-viewer'
          navigation={NavigationToolbar}
          showToolbar={true}
          showNavbar={true}
          page={1}
          enableScroll={true}
          onPageRenderSuccess={(page, canvasEl, viewport) => {
            setSize([`${Math.round(viewport.width / CSS_UNITS)}px`, `${Math.round(viewport.height / CSS_UNITS)}px`]);
          }}
        />
      </div>)}
    {content.file?.download && (<a href={flattenToAppURL(content.file.download)}>
      {content.file.filename}
    </a>)}


  </Container>);
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FileView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string, description: PropTypes.string, file: PropTypes.shape({
      download: PropTypes.string, filename: PropTypes.string,
    }),
  }).isRequired,
};

export default FileView;
