export const FacultyCVSchema = (props) => {
  const { intl, schema, formData } = props;
  return {
    ...schema,
    fieldsets: [
      ...schema.fieldsets,
      {
        id: 'options',
        title: 'Display options',
        fields: ['templateSelected', 'titleTag', 'showImage'],
      },
    ],
    properties: {
      ...schema.properties,
      templateSelected: {
        title: 'Template',
        choices: [
          ['list', 'List'],
          ['card', 'Card'],
          ['hero', 'Hero'],
          ['grid', 'Grid'],
          ['segmented', 'Segmented'],
        ],
        default: 'list',
      },
      titleTag: {
        title: 'Heading Level',
        choices: [
          ['h2', 'H2'],
          ['h3', 'H3'],
          ['h4', 'H4'],
          ['p', 'P'],
        ],
        default: 'h2',
      },
      showImage: {
        title: 'Show Image',
        description: 'Show the image of the faculty members',
        type: 'boolean',
        default: true,
      },
    },
  };
};
