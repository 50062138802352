import { RAW } from '../../constants/ActionTypes';
import { ADD_COMMENT } from '@plone/volto/constants/ActionTypes'

const initialState = {
  loaded: false,
  loading: false,
  error: null,
};

export default function raw(state = initialState, action = {}) {
  switch (action.type) {
    case `${RAW}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${ADD_COMMENT}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${RAW}_SUCCESS`:
      return {
        ...state,
        ...action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${ADD_COMMENT}_SUCCESS`:
      return {
        ...state,
        ...action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${RAW}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    case `${ADD_COMMENT}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
