import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';

const ListingData = (props) => {
  const { data, block, blocksConfig, onChangeBlock, navRoot, contentType } = props;
  const intl = useIntl();
  const schema = blocksConfig.listing.blockSchema({
    ...props,
    intl,
  });

  // State to manage if the data is loaded
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // When the data is loaded, trigger the animation
    setIsLoaded(true);
  }, []);

  return (
    <div>
      <BlockDataForm
        schema={schema}
        title={schema.title}
        onChangeField={(id, value) => {
          onChangeBlock(block, {
            ...data,
            [id]: value,
          });
        }}
        onChangeBlock={onChangeBlock}
        formData={data}
        blocksConfig={blocksConfig}
        block={block}
        navRoot={navRoot}
        contentType={contentType}
      />
    </div>
  );
};

ListingData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default ListingData;
