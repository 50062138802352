import loadable from '@loadable/component';
import iconSVG from '@plone/volto/icons/payment.svg';

// Dynamically import the View and Edit components using loadable
const LazyView = loadable(() => import('./View'));
const LazyEdit = loadable(() => import('./Edit'));

const applyConfig = (config) => {
  config.blocks.blocksConfig.blackBaud = {
    id: 'blackBaud',
    title: 'BlackBaud Form',
    icon: iconSVG,
    group: 'common',
    view: LazyView,
    edit: LazyEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  return config;
};

export default applyConfig;
