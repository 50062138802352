/**
 * Voting reducer.
 * @module reducers/votes/votes
 */

import {
  CLEAR_DEPARTMENT_VOTES,
  GET_DEPARTMENT_VOTES,
  DEPARTMENT_VOTE,
} from 'volto-facultycv/constants/ActionTypes';

const initialState = {
  loaded: false,
  loading: false,
  error: null,
};

/**
 * Voting reducer.
 * @function votes
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function department_votes(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_DEPARTMENT_VOTES}_PENDING`:
    case `${DEPARTMENT_VOTE}_PENDING`:
    case `${CLEAR_DEPARTMENT_VOTES}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_DEPARTMENT_VOTES}_SUCCESS`:
      return {
        ...state,
        ...action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${DEPARTMENT_VOTE}_SUCCESS`:
    case `${CLEAR_DEPARTMENT_VOTES}_SUCCESS`:
      return {
        ...state,
        ...action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${GET_DEPARTMENT_VOTES}_FAIL`:
    case `${DEPARTMENT_VOTE}_FAIL`:
    case `${CLEAR_DEPARTMENT_VOTES}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
