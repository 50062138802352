import React from 'react';
import loadable from '@loadable/component';
import raw from './reducers/raw/raw';
import review_state from './reducers/review_state/review_state';

// Dynamically load the ProgressWorkflow component
const ProgressWorkflow = loadable(() => import('./ProgressWorkflow'), {
  fallback: <div>Loading...</div>,
});

const applyConfig = (config) => {
  config.addonReducers.raw = raw;
  config.addonReducers.review_state = review_state;

  config.settings.appExtras = [
    ...(config.settings.appExtras || []),
    {
      match: '',
      component: ProgressWorkflow,
    },
  ];

  return config;
};

export default applyConfig;
