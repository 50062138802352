const applyConfig = (config) => {
  config.experimental.addBlockButton.enabled = true;
  config.settings.navDepth = 3;
  config.settings.pluggableStyles = [
    ...(config.settings.pluggableStyles || []),
    {
      id: 'primaryBox',
      title: 'Primary',
      cssClass: 'primary-box',
    },
    {
      id: 'secondaryBox',
      title: 'Secondary',
      cssClass: 'secondary-box',
    },
    {
      id: 'tertiaryBox',
      title: 'Tertiary',
      cssClass: 'tertiary-box',
    },
    {
      id: 'grayBox',
      title: 'Gray',
      cssClass: 'gray-box',
    },
  ];
  config.settings.available_colors = [
    '#d92518',
    '#2e3233',
    '#FFB907',
    '#e1e1e1',
    '#eeeeee',
    '#ffffff',
    '#000000',
  ];
  config.settings.image_crop_aspect_ratios = [
    {
      label: '16:9',
      ratio: 16 / 9,
    },
    {
      label: '1:1',
      ratio: 1,
    },
    {
      label: '2:3',
      ratio: 2 / 3,
    },
  ];
  // Blacklist specific listing variations
  const blacklistedVariations = ['summary', 'cardsCarousel', 'cardsGallery'];
  config.blocks.blocksConfig.listing.variations = config.blocks.blocksConfig.listing.variations.filter(
    (variation) => !blacklistedVariations.includes(variation.id),
  );
  config.settings.siteTitleFormat = {
    includeSiteTitle: true,
    titleAndSiteTitleSeparator: '-',
  };
  return config;
};

export default applyConfig;
